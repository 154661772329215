/* You can add global styles to this file, and also import other style files */

@import "./assets/css/mixins/mixins";
@import "./assets/css/mixins/colors";
app-root {
    display: block;
}

input.inline {
    background: none;
    border: none;
    &:focus {
        outline: none;
    }
}

html {
    box-sizing: border-box;
}

* {
    &,
    &::after,
    &::before {
        box-sizing: inherit;
    }
}

html,
body {
    height: 100%;
    //    max-height: calc((var(--vh, 1vh) * 100));
    @include media_xs {
        //      height: calc((var(--vh, 1vh) * 100));
    }
}

.main_wrap {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

a:hover,
a:focus,
a:active {
    outline: none;
}

textarea,
input,
select,
button,
a {
    outline: none;
    box-shadow: none !important;
    font-family: inherit;
}

textarea {
    -webkit-appearance: none;
}

@font-face {
    font-family: "Merriweather";
    src: url(assets/fonts/Merriweather/Merriweather-Regular.ttf);
}

@font-face {
    font-family: "Mukta";
    src: url(assets/fonts/Mukta/Mukta-Regular.ttf);
}

@font-face {
    font-family: "Mukta-medium";
    src: url(assets/fonts/Mukta/Mukta-Medium.ttf);
}

body {
    background-color: #fafafa;
    margin: 0;
}

* {
    button {
        cursor: pointer;
    }
}

.row {
    display: flex;
}

.column {
    display: flex;
    flex-direction: column;
}

.a_center {
    align-items: center;
}

.a_end {
    align-items: flex-end;
}

.j_space {
    justify-content: space-between;
}

app-form-input,
app-form-select,
app-form-autocomplete,
app-form-datepicker,
app-add-autocomplete,
app-color-picker,
app-form-textarea {
    // padding: 0 10px;
    // margin-bottom: 8px;
    // width: 25%;
    // max-width: 215px;
    // min-width: 195px;
    // flex-grow: 215;
    &.md {
        width: 50%;
        max-width: 430px;
        min-width: 300px;
        flex-grow: 430;
    }
    &.lg {
        width: 70%;
        max-width: 645px;
        min-width: 300px;
        flex-grow: 645;
    }
    &.elg {
        width: 100%;
        max-width: 860px;
        min-width: 300px;
        flex-grow: 860;
    }
}

.modal-white {
    .mat-dialog-container {
        position: relative;
        background-color: #fff;
        color: #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.modal-green {
    .mat-dialog-container {
        position: relative;
        background-color: #4f736c;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.w-100 {
    width: 100% !important;
}

.fg-1 {
    flex-grow: 1;
}

.fg-2 {
    flex-grow: 2;
}

.fg-3 {
    flex-grow: 3;
}

.fg-4 {
    flex-grow: 4;
}

.mr-16{
    margin-right:16px;
}

//disable input number buttons
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

////menu list///
// .select_wrap {
//     position: relative;
//     color: red;
//     max-width: 100%;
//     width: 100%;
//     .select_btn {
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         justify-content: space-between;
//         text-align: left;
//         width: 100%;
//         height: 40px;
//         background-color: #fff;
//         padding: 0 10px;
//         border: 1px solid red;
//         border-radius: 4px;
//         // @include hover {
//         //     &:not(.active) {
//         //         border-color: red;
//         //         box-shadow: 0 0 0pt 1pt red !important;
//         //     }
//         // }
//     }
//     .value {
//         max-width: calc(100% - 14px);
//         overflow: hidden;
//         white-space: nowrap;
//         text-overflow: ellipsis;
//         &.grid {
//             display: flex;
//             align-items: center;
//         }
//         .prefix {
//             padding-right: 0;
//         }
//     }
//     .select {
//         position: relative;
//         width: 100%;
//     }
//     input {
//         color: red;
//         width: 100%;
//         height: 40px;
//         background-color: #fff;
//         padding: 0 10px;
//         border: 1px solid red;
//         border-radius: 4px;
//         padding-right: 35px;
//         // @include hover {
//         //     &:not([disabled]):not([readonly]) {
//         //         border-color: red;
//         //         box-shadow: 0 0 0pt 1pt red !important;
//         //     }
//         // }
//         &:focus {
//             border-color: red;
//             box-shadow: 0 0 0pt 1pt rgba(red, 0) !important;
//         }
//     }
//     .arrow {
//         position: relative;
//         width: 14px;
//         height: 14px;
//         margin-left: auto;
//         &::before,
//         &::after {
//             content: "";
//             position: absolute;
//             top: 6px;
//             width: 9px;
//             height: 1px;
//             background-color: red;
//         }
//         // &::before {
//         //     left: 0;
//         //     @include transform(rotate(45deg));
//         // }
//         // &::after {
//         //     right: 0;
//         //     @include transform(rotate(-45deg));
//         // }
//     }
//     .select_list {
//         position: absolute;
//         left: 0;
//         top: calc(100% + 12px);
//         width: 100%;
//         z-index: 5;
//         box-shadow: 0 2px 4px #dce1e7;
//         &.top_el {
//             top: inherit;
//             bottom: calc(100% + 12px);
//         }
//         ul {
//             width: 100%;
//             overflow-y: auto;
//             box-sizing: border-box;
//             max-height: 300px;
//             box-shadow: 0 2px 4px #dce1e7;
//             border: 1px solid red;
//             border-radius: 4px;
//             background-color: #fff;
//             padding: 5px;
//             margin: 0;
//             li {
//                 border-radius: 4px;
//                 cursor: pointer;
//                 width: 100%;
//                 min-height: 30px;
//                 display: flex;
//                 align-items: center;
//                 background-color: #fff;
//                 padding: 0 5px;
//                 white-space: nowrap;
//                 overflow: hidden;
//                 text-overflow: ellipsis;
//                 // @include hover {
//                 //     background-color: red;
//                 // }
//                 &.active {
//                     color: red;
//                     text-decoration: underline;
//                 }
//                 &.grid {
//                     // justify-content: space-between;
//                     app-link-button {
//                         margin-left: auto;
//                     }
//                 }
//             }
//         }
//     }
//     &.active {
//         .select_btn {
//             border-color: red;
//         }
//         // .arrow {
//         //     &::before {
//         //         @include transform(rotate(-45deg));
//         //     }
//         //     &::after {
//         //         @include transform(rotate(45deg));
//         //     }
//         // }
//     }
//     [disabled],
//     [readonly] {
//         cursor: not-allowed;
//         background-color: red;
//     }
//     .prefix {
//         padding-right: 5px;
//     }
//     .mulit_select_wrap {
//         width: calc(100% + 10px);
//         margin-left: -5px;
//         margin-top: 5px;
//         display: flex;
//         flex-wrap: wrap;
//         .single_item {
//             display: inline-flex;
//             align-items: center;
//             position: relative;
//             color: #fff;
//             font-weight: 500;
//             min-width: 125px;
//             min-height: 30px;
//             border-radius: 4px;
//             background-color: red;
//             padding: 0 22px 0 10px;
//             margin: 0 5px 5px;
//             span {
//                 max-width: 100%;
//                 overflow: hidden;
//                 white-space: nowrap;
//                 text-overflow: ellipsis;
//             }
//             .close_btn {
//                 cursor: pointer;
//                 position: absolute;
//                 right: 0;
//                 top: 52%;
//                 //   @include transform(translateY(-50%));
//                 width: 22px;
//                 height: 22px;
//                 background-color: transparent;
//                 border: none;
//                 padding: 0;
//                 text-align: center;
//                 img {
//                     width: 12px;
//                 }
//             }
//         }
//     }
// }
// .menu_list {
//     width: 100%;
//     overflow-y: auto;
//     max-height: 300px;
//     box-shadow: 0 2px 4px #dce1e7;
//     border: 1px solid red;
//     border-radius: 4px;
//     background-color: #fff;
//     padding: 5px;
//     margin: 0;
//     li {
//         border-radius: 4px;
//         cursor: pointer;
//         width: 100%;
//         min-height: 30px;
//         display: flex;
//         align-items: center;
//         background-color: #fff;
//         padding: 0 5px;
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         // @include hover {
//         //     &:not(.wclose) {
//         //         background-color: $lightNavy;
//         //     }
//         // }
//         &.active {
//             color: red;
//             text-decoration: underline;
//         }
//         &.clear {
//             position: relative;
//             margin-bottom: 4px;
//             &::after {
//                 content: "";
//                 position: absolute;
//                 left: 0;
//                 bottom: 0;
//                 width: 100%;
//                 height: 1px;
//                 background-color: red;
//             }
//         }
//         &.wclose {
//             cursor: auto;
//             display: flex;
//             justify-content: space-between;
//             border-radius: 0;
//             padding: 0;
//         }
//         .value {
//             cursor: pointer;
//             display: flex;
//             align-items: center;
//             width: calc(100% - 35px);
//             min-height: 30px;
//             white-space: nowrap;
//             overflow: hidden;
//             text-overflow: ellipsis;
//             padding: 0 5px;
//             border-radius: 4px;
//             // @include hover {
//             //     background-color: $lightNavy;
//             // }
//         }
//         .close_btn {
//             cursor: pointer;
//             width: 25px;
//             height: 30px;
//             display: flex;
//             align-items: center;
//             justify-content: center;
//             border-radius: 4px;
//             // @include hover {
//             //     background-color: rgba($red, 0.3);
//             //     svg-icon {
//             //         svg {
//             //             fill: $red;
//             //         }
//             //     }
//             // }
//             svg-icon {
//                 position: relative;
//                 width: 12px;
//                 top: 3px;
//                 left: 3px;
//                 svg {
//                     fill: red;
//                 }
//             }
//         }
//     }
// }
// ////work area tiles////
// .wa_tiles_wrap {
//     width: 100%;
// }
// .wa_tiles {
//     width: 100%;
//     margin-bottom: 40px;
//     .wa_loader {
//         min-height: 20vh;
//     }
//     .label {
//         font-weight: 500;
//         margin-bottom: 8px;
//     }
//     app-label {
//         width: 100%;
//     }
//     &.row {
//         width: calc(100% + 20px);
//         margin-left: -10px;
//         app-label {
//             padding: 0 10px;
//         }
//         .single_wa_tile_wrap {
//             padding: 0 10px;
//         }
//     }
//     .wa_tiles_wrap {
//         &.margin {
//             margin-bottom: 39px;
//             &:last-of-type {
//                 margin-bottom: 0;
//             }
//         }
//     }
// }
//Confetti
.confetti_container {
    // width: 100vw;
    width: 100%;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 800;
    pointer-events: none;
}

@keyframes confetti-s {
    0% {
        transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }
    100% {
        transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
    }
}

@keyframes confetti-m {
    0% {
        transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }
    100% {
        transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
    }
}

@keyframes confetti-f {
    0% {
        transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
    }
    100% {
        transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
    }
}

.confetti {
    position: absolute;
    z-index: 1;
    top: -10px;
    &.c-a-s {
        animation: confetti-s 2.5s linear 1 forwards;
    }
    &.c-a-m {
        animation: confetti-m 2s linear 1 forwards;
    }
    &.c-a-f {
        animation: confetti-f 1.8s linear 1 forwards;
    }
}