@import './colors';
//for scond button
@mixin secondary-button {
    button {
        display: flex;
        align-items: center;
        padding: 0;
        border: none;
        background: none;
        transition: 0.5s;
        span {
            padding-left: 7px;
            font-family: Mukta-medium;
            font-style: normal;
            color: $darkGrey;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.09em;
            white-space: nowrap;
            text-transform: uppercase;
        }
        svg-icon {
            transition: 0.5s;
            circle,
            path {
                transition: 0.5s;
            }
        }
    }
}

//animation for second button
@mixin animation-increase($scale, $time) {
    transform: scale($scale);
    -ms-transform: scale($scale);
    -webkit-transform: scale($scale);
    -moz-transform: scale($scale);
    -o-transform: scale($scale);
    transition: $time;
}

//All elements
@mixin animation-increase-common($scale, $time, $opacity, $movey) {
    transform: scale(1) translateZ(0);
    -ms-transform: scale(1) translateZ(0);
    -webkit-transform: scale(1) translateZ(0);
    -moz-transform: scale(1) translateZ(0);
    -o-transform: scale(1) translateZ(0);
    transition: $time cubic-bezier(.51, .92, .24, 1.15);
    -webkit-transition: $time cubic-bezier(.51, .92, .24, 1.15);
    //  transform-origin: 0% 0;
    &:hover {
        transform: translateZ(0) scale($scale) translateY($movey);
        -ms-transform: translateZ(0) scale($scale) translateY($movey);
        -webkit-transform: translateZ(0) scale($scale) translateY($movey);
        -moz-transform: translateZ(0) scale($scale) translateY($movey);
        -o-transform: translateZ(0) scale($scale) translateY($movey);
        transition: $time cubic-bezier(.51, .92, .24, 1.15);
        -webkit-transition: $time cubic-bezier(.51, .92, .24, 1.15);
        opacity: $opacity;
        // transform-origin: 0% 0;
    }
}

@mixin animation-increase-common-text($scale, $time, $opacity, $movey) {
    transform: translateZ(0);
    -ms-transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -o-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
    -webkit-transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
    // font-size: 1em;
    // transform-origin: 0% 0;
    &:hover {
        transform: translateY($movey);
        -ms-transform: translateY($movey);
        -webkit-transform: translateY($movey);
        -moz-transform: translateY($movey);
        -o-transform: translateY($movey);
        transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
        font-size: $scale;
        -webkit-transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
        opacity: $opacity;
        // transform-origin: 0% 0;
    }
}

@mixin animation-increase-headers-text($time, $opacity) {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
    -webkit-transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
    // font-size: 1em;
    // transform-origin: 0% 0;
    &:hover {
        transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
        -webkit-transition: $time cubic-bezier(0.15, 0.38, 0.77, 1);
        opacity: $opacity;
        text-shadow: 1px 1px 13px #000;
        // transform-origin: 0% 0;
    }
}

//disable scroll bar
@mixin disable_scroll_bar {
    @include media_to_xs() {
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

// Large select - 57 px
@mixin l_select {
     ::ng-deep {
        .select_wrap button {
            height: 57px !important;
        }
        .selected:not(.active) button .placeholder-container {
            background: linear-gradient(180deg, transparent calc(50% - 1.5px), $white calc(50% - 1.5px));
            background: -webkit-linear-gradient(180deg, transparent calc(50% - 1.5px), $white calc(50% - 1.5px));
            background: -moz-linear-gradient(180deg, transparent calc(50% - 1.5px), $white calc(50% - 1.5px));
            background: -o-linear-gradient(180deg, transparent calc(50% - 1.5px), $white calc(50% - 1.5px));
            background: -ms-linear-gradient(180deg, transparent calc(50% - 1.5px), $white calc(50% - 1.5px));
        }
        .placeholder_top {
            .placeholder-container {
                transform: translate(18px, calc(-100% - 8px));
                -ms-transform: translate(18px, calc(-100% - 8px));
                -webkit-transform: translate(18px, calc(-100% - 8px));
                -moz-transform: translate(18px, calc(-100% - 8px));
                -o-transform: translate(18px, calc(-100% - 8px));
            }
        }
    }
}

//51px no placeholder top
@mixin small_text_input {
    app-input-field {
         ::ng-deep {
            .column {
                &.placeholder_top .row {
                    .placeholder-container {
                        display: none;
                    }
                }
                .row {
                    input {
                        height: 51px;
                        padding: 16px 22px 16px 18px;
                        line-height: 17px;
                    }
                    .placeholder-container {
                        transform: translate(19px, 18px);
                    }
                }
            }
        }
    }
}

//style for plus  minus btn
// @mixin plus_btn {
//     min-width: 25px;
//     min-height: 25px;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     border: none;
//     background-color: transparent;
//     outline: none;
//     // background-image: url("../../../assets/svg/plus-icon.svg");
//  }
// @mixin minus_btn {
//     min-width: 25px;
//     min-height: 25px;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-size: contain;
//     border: none;
//     background-color: transparent;
//     outline: none;
//     background-image: url("../../../assets/svg/minus-icon.svg");
// }
//hover
@mixin hover {
    @media screen and (min-width: 1025px) {
        &:hover {
            @content;
        }
    }
}

//media query
@mixin media_to($width: 1199px) {
    @media screen and (max-width: $width) {
        @content;
    }
}

@mixin media_from($min: 1025px) {
    @media screen and (min-width: $min) {
        @content;
    }
}

@mixin media_from_to($min: 1025px, $max: 1199px) {
    @media screen and (min-width: $min) and (max-width: $max) {
        @content;
    }
}

@mixin media_sm {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin media_to_sm {
    @media screen and (min-width: 1025px) {
        @content;
    }
}

@mixin media_only_sm {
    @media screen and (min-width: 768px) and (max-width: 1024px) {
        @content;
    }
}

@mixin media_xs {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@mixin media_to_xs {
    @media screen and (min-width: 768px) {
        @content;
    }
}

@mixin media_max_height_to_sm($width: 650px) {
    @media screen and (min-width: 1025px) and (max-height: $width) {
        @content;
    }
}

//hidden and visible
.hidden {
    display: none !important;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
    display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
    table.visible-xs {
        display: table !important;
    }
    tr.visible-xs {
        display: table-row !important;
    }
    th.visible-xs,
    td.visible-xs {
        display: table-cell !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .visible-sm {
        display: block !important;
    }
    table.visible-sm {
        display: table !important;
    }
    tr.visible-sm {
        display: table-row !important;
    }
    th.visible-sm,
    td.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .visible-sm-inline {
        display: inline !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }
    table.visible-md {
        display: table !important;
    }
    tr.visible-md {
        display: table-row !important;
    }
    th.visible-md,
    td.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }
    table.visible-lg {
        display: table !important;
    }
    tr.visible-lg {
        display: table-row !important;
    }
    th.visible-lg,
    td.visible-lg {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}